import {
  RESET_CAMPAIGN,
  REQUEST_CAMPAIGN,
  FETCH_CAMPAIGN_SUCCESS,
  FETCH_CAMPAIGN_FAILURE
} from '../actions/actionTypes';
import { LOCATION_CHANGE } from 'redux-first-history'

const initialState = {
    payload: {},
    status: null,
    error: null,
    loading: false
}

export const campaignReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOCATION_CHANGE:
      return {
        ...state,
        loading:false,
        status: initialState.status,
        payload: initialState.payload,
        error: initialState.error
      }
    case RESET_CAMPAIGN:
      return {
        ...state,
        loading:false,
        status: initialState.status,
        payload: initialState.payload,
        error: initialState.error
      }
    case REQUEST_CAMPAIGN:
      return {
        ...state,
        loading:true,
        status: initialState.status,
        payload: initialState.payload,
        error: initialState.error
      }
    case FETCH_CAMPAIGN_SUCCESS:
      return {
        ...state,
        payload: action.payload,
        loading: false,
        status: action.status,
        error: initialState.error,
      };
    case FETCH_CAMPAIGN_FAILURE:
      return {
        ...state,
        loading: false,
        status: action.status,
        error: action.error,
      };
    default:
      return state;
  }
};