import {
  REQUEST_ORDER_BY_ID,
  FETCH_ORDER_BY_ID_SUCCESS,
  FETCH_ORDER_BY_ID_FAILURE,
} from '../actions/actionTypes';

const initialState = {
    payload: {},
    status: null,
    error: null,
    loading: true
}

export const orderReducer = (state = initialState, action) => {
  switch (action.type) {
    case REQUEST_ORDER_BY_ID:
      return {
        ...state,
        loading: true,
        payload: initialState.payload,
        error: initialState.error,
      };
    case FETCH_ORDER_BY_ID_SUCCESS:
      return {
        ...state,
        payload: action.payload,
        loading: false,
        status: action.status,
        error: initialState.error,
      };
    case FETCH_ORDER_BY_ID_FAILURE:
      return {
        ...state,
        loading: false,
        status: action.status,
        error: action.error,
      };
    default:
      return state;
  }
};