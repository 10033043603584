import {
  REQUEST_BOOKS,
  FETCH_BOOKS_SUCCESS,
  FETCH_BOOKS_FAILURE,
} from '../actions/actionTypes';

const initialState = {
    payload: [],
    status: null,
    error: null,
    loading: true,
    headers: null
}

export const booksReducer = (state = initialState, action) => {
  switch (action.type) {
    case REQUEST_BOOKS:
      return {
        ...state,
        loading: true,
        status: initialState.status,
        payload: initialState.payload,
        error: initialState.error,
      };
    case FETCH_BOOKS_SUCCESS:
      return {
        ...state,
        payload: action.payload,
        loading: false,
        status: action.status,
        error: initialState.error,
        headers: action.headers
      };
    case FETCH_BOOKS_FAILURE:
      return {
        ...state,
        loading: false,
        status: action.status,
        error: action.error,
      };
    default:
      return state;
  }
};