import {
  INCREMENT_DOWNLOAD_SUCCESS,
  INCREMENT_DOWNLOAD_FAILURE
} from '../actions/actionTypes';

const initialState = {
    payload: {},
    status: null,
    error: null,
    loading: true
}

export const incrementDownloadReducer = (state = initialState, action) => {
  switch (action.type) {
    case INCREMENT_DOWNLOAD_SUCCESS:
      return {
        ...state,
        payload: action.payload,
        loading: false,
        status: action.status,
        error: initialState.error,
      };
    case INCREMENT_DOWNLOAD_FAILURE:
      return {
        ...state,
        loading: false,
        status: action.status,
        error: action.error,
      };
    default:
      return state;
  }
};