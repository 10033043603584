import {
  REQUEST_BOOK_BY_ID,
  FETCH_BOOK_BY_ID_SUCCESS,
  FETCH_BOOK_BY_ID_FAILURE,
} from '../actions/actionTypes';
import { LOCATION_CHANGE } from 'redux-first-history'

const initialState = {
    payload: {},
    status: null,
    error: null,
    loading: true
}

export const bookReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOCATION_CHANGE:
      return {
        ...state,
        loading:true,
        status: initialState.status,
        payload: initialState.payload,
        error: initialState.error
      }
    case REQUEST_BOOK_BY_ID:
      return {
        ...state,
        loading: true,
        status: initialState.status,
        payload: initialState.payload,
        error: initialState.error,
      };
    case FETCH_BOOK_BY_ID_SUCCESS:
      return {
        ...state,
        payload: action.payload,
        loading: false,
        status: action.status,
        error: initialState.error,
      };
    case FETCH_BOOK_BY_ID_FAILURE:
      return {
        ...state,
        loading: false,
        status: action.status,
        error: action.error,
      };
    default:
      return state;
  }
};