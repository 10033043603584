import {
  RESET_CHECKOUT,
  REQUEST_CHECKOUT,
  VERIFY_CHECKOUT_SUCCESS,
  VERIFY_CHECKOUT_FAILURE,
} from '../actions/actionTypes';

const initialState = {
    payload: {},
    status: null,
    error: null,
    loading: false
}

export const checkoutReducer = (state = initialState, action) => {
  switch (action.type) {
    case RESET_CHECKOUT:
      return {
        ...state,
        status: initialState.status,
        loading: initialState.loading,
        payload: initialState.payload,
        error: initialState.error
      }
    case REQUEST_CHECKOUT:
      return {
        ...state,
        status: initialState.status,
        loading:true,
        payload: initialState.payload,
        error: initialState.error
      }
    case VERIFY_CHECKOUT_SUCCESS:
      return {
        ...state,
        payload: action.payload,
        loading: false,
        status: action.status,
        error: initialState.error,
      };
    case VERIFY_CHECKOUT_FAILURE:
      return {
        ...state,
        loading: false,
        status: action.status,
        error: action.error,
      };
    default:
      return state;
  }
};