import {createStore, compose, applyMiddleware} from 'redux'
import thunkMiddleware from 'redux-thunk'
import { combineReducers } from 'redux';
import { globalHistory } from '@reach/router';
import { createReduxHistoryContext, reachify } from 'redux-first-history'
import { createBrowserHistory, createMemoryHistory } from 'history'

import { campaignReducer } from './redux/reducers/campaignReducers'
import { booksReducer } from './redux/reducers/booksReducers'
import { bookReducer } from './redux/reducers/bookReducers'
import { checkoutReducer } from './redux/reducers/checkoutReducers'
import { orderReducer } from './redux/reducers/orderReducers'
import { incrementDownloadReducer } from './redux/reducers/incrementDownloadReducers'

let browserHistory
browserHistory = typeof window !== 'undefined' ? createBrowserHistory() : createMemoryHistory();

const { createReduxHistory, routerMiddleware, routerReducer } = createReduxHistoryContext({ 
  history: browserHistory,
  reachGlobalHistory: globalHistory,
});

const rootReducer = combineReducers({
  router: routerReducer,
  campaign: campaignReducer,
  books: booksReducer,
  book: bookReducer,
  checkout: checkoutReducer,
  order: orderReducer,
  incrementDownload: incrementDownloadReducer,
});

const middewares = [
  // Add other middleware on this line...

  // thunk middleware can also accept an extra argument to be passed to each thunk action
  // https://github.com/gaearon/redux-thunk#injecting-a-custom-argument
  routerMiddleware,
  thunkMiddleware,
];

export const store = createStore(rootReducer, compose(
    applyMiddleware(...middewares),
    (typeof window !== `undefined` && window.__REDUX_DEVTOOLS_EXTENSION__) ? window.__REDUX_DEVTOOLS_EXTENSION__() : f => f // add support for Redux dev tools
  )
)


export const history = createReduxHistory(store);
export const reachHistory = reachify(history);